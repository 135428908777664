<template>
  <div class="back">

  </div>
<!--<img class="back">-->
</template>

<script>
import imgtest from "@/assets/image/background.jpg"
export default {
  name: 'App',
  setup(){
    return{
      imgtest
    }
  },
  data() {
    return {

    }
  },
  components: {

  }
}
</script>

<style>
.back {
  background-image: url("./assets/image/1.jpg");
  background-size: cover;
  height: 100vh;
}

</style>
